<div class="header-container">
    <div class="logo-container">
        <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
            <nb-icon icon="menu-2-outline"></nb-icon>
        </a>
        <a class="logo" href="#" (click)="navigateHome()"><span>Inventory</span></a>
    </div>
</div>
<div class="header-container">
    <nb-actions size="small">

        <!--<nb-action class="control-item">
      <nb-search type="rotate-layout"></nb-search>
    </nb-action>
    <nb-action class="control-item" icon="email-outline"></nb-action>
    <nb-action class="control-item" icon="bell-outline"></nb-action>-->
        <nb-action class="user-action" *nbIsGranted="['view', 'user']">
            <!--<nb-user [nbContextMenu]="userMenu"
               [onlyPicture]="userPictureOnly"
               [name]="user?.name"
               [picture]="user?.picture">-->
            <nb-user [nbContextMenu]="userMenu" [nbContextMenuTag]="tag" [name]="user">
            </nb-user>
        </nb-action>
    </nb-actions>
</div>