import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';

export const routes: Routes = [  
  {
    path: 'item',
    loadChildren: () => import('./item/item.module')
      .then(m => m.ItemModule),
  },
  {
    path: 'add-inventory',
    loadChildren: () => import('./add-inventory/add-inventory.module')
      .then(m => m.AddInventoryModule),
  },
  {
    path: 'deduct-inventory',
    loadChildren: () => import('./deduct-inventory/deduct-inventory.module')
      .then(m => m.DeductInventoryModule),
  },
  {
    path: 'inventory-planning',
    loadChildren: () => import('./inventory-planning/inventory-planning.module')
      .then(m => m.InventoryPlanningModule),
  },
  {
    path: 'deduct-inventory-planning',
    loadChildren: () => import('./deduct-inventory-planning/deduct-inventory-planning.module')
      .then(m => m.DeductInventoryPlanningModule),
  },
  {
    path: 'relation',
    loadChildren: () => import('./relation/relation.module')
      .then(m => m.RelationModule),
  },
  {
    path: 'allocation',
    loadChildren: () => import('./allocation/allocation.module')
      .then(m => m.AllocationModule),
  },
  {
    path: 'inventory',
    loadChildren: () => import('./inventory/inventory.module')
      .then(m => m.InventoryModule),
  },
  {
    path: 'pages',
    loadChildren: () => import('./pages/pages.module')
      .then(m => m.PagesModule),
  },
  {
    path: 'users',
    loadChildren: () => import('./user/user.module')
      .then(m => m.UserModule),
  },
  {
    path: 'change-password',
    loadChildren: () => import('./change-password/change-password.module')
      .then(m => m.ChangePasswordModule),
  },
  {
    path: 'register',
    loadChildren: () => import('./register/register.module')
      .then(m => m.RegisterModule),
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: 'inventory-scanner',
    loadChildren: () => import('./inventory-scanner/inventory-scanner.module').then(m => m.InventoryScannerModule),
  },
  { path: '', redirectTo: 'auth/login', pathMatch: 'full' },
  { path: '**', redirectTo: 'auth/login' },
  /*{ path: '', redirectTo: 'pages', pathMatch: 'full' },
  { path: '**', redirectTo: 'pages' },*/
];

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
