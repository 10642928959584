import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbAuthResult, NbAuthService } from '@nebular/auth';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';

import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { filter } from 'rxjs/operators/filter';
import { LayoutService } from '../../../@core/utils';
@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'dark';

  //userMenu = [ { title: 'Profile', url:'auth/profile-edit' }, { title: 'Log out', url:'auth/logout' } ];
  userMenu = [ 
    { title: 'Manage Users', url: '/users', icon: 'person' },
    { title: 'Change Password', url: '/change-password', icon: "lock-outline" },
    { title: 'Log out', icon: "lock-outline" } 
  ];
  tag = 'my-context-menu';
  redirectDelay: number = 100;
  strategy: string = 'email';
  errors: string[] = [];
  messages: string[] = [];
  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private themeService: NbThemeService,
              private layoutService: LayoutService,
              private breakpointService: NbMediaBreakpointsService,
              protected service: NbAuthService,
              protected router: Router) {

              menuService.onItemClick()
                .pipe(filter(({ tag }) => tag === this.tag))
                .subscribe(bag => this.logout());
  }

  logout(){
    
    this.service.logout(this.strategy).subscribe((result: NbAuthResult) => {

      //alert("test");
     //console.log(result);
        if (result.isSuccess()) {
          localStorage.clear();
          this.messages = result.getMessages();
          //console.log(this.messages);
        } else {
          this.errors = result.getErrors();
          console.log(this.errors);
        }
        this.router.navigate(["/auth/login"]);
  
     /*  const redirect = result.getRedirect();
      if (redirect) {
        setTimeout(() => {
          return this.router.navigateByUrl("/auth/login");
        }, this.redirectDelay);
      } */
    });
  }

  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;
    this.user = localStorage.getItem('user_name');

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }
}
