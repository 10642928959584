//import * as lskeys from './../localstorage.items';
import { Observable } from 'rxjs/Observable';
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders } from '@angular/common/http';
import { NbAuthService,NbAuthJWTToken } from '@nebular/auth';
import {Router,ActivatedRoute} from "@angular/router";

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {

    constructor(protected service: NbAuthService,private router: Router,private route: ActivatedRoute) {
        //localStorage.clear();
        //console.log(localStorage);
        //console.log(this.router.url);

      
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


        if (true) { // e.g. if token exists, otherwise use incomming request.
           
          
          let parameter = "";
            this.route.queryParams.subscribe(params => {
                //alert(params.error);
                parameter = params.error;
            });
           // alert(parameter);
           //console.log(this.router.url)
            if(parameter == "add" || parameter == "del" || this.router.url=='/item/item-list' || this.router.url.includes('inventory') || this.router.url=='/relation/relation-list' || this.router.url=='/allocation/allocation-list' || this.router.url=='/inventory/inventory-list' || this.router.url=='/inventory/inventory-tree-gred' || this.router.url=='/auth/logout' || this.router.url=='/users'){
                return next.handle(req.clone({
                    setHeaders: {
                        "Authorization":'Token '+JSON.parse(localStorage.auth_app_token).value,
                     
                    }
                }));
            } else{                
                return next.handle(req.clone({
                    setHeaders: {                     
                        
                    
                    }
                }));
            }

            

           
        }
        else {
            return next.handle(req);
        }
    }

    
}