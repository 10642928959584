import { Component } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
  <div class="myra-logo-customize">
      <sub style="margin-right: 0.5rem;">Powered By</sub>
      <img src="assets/images/_logo-myra.png"/>
  </div>
    <!--<span class="created-by">
    Copyright © 2021. All rights reserved
    </span>-->
    <!--<div class="socials">
      <a href="#" target="_blank" class="ion ion-social-github"></a>
      <a href="#" target="_blank" class="ion ion-social-facebook"></a>
      <a href="#" target="_blank" class="ion ion-social-twitter"></a>
      <a href="#" target="_blank" class="ion ion-social-linkedin"></a>
    </div>-->
  `,
})
export class FooterComponent {
}
