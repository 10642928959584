import { Injectable } from '@angular/core';
import { ItemData } from '../data/item';

@Injectable()
export class ItemService extends ItemData {

  data = [{
    id: 1,
    category: 'Material ',
    item_type: 'item type0',
    item_name: 'item name0',
    other_details: 'Other details0',
   
  }, {
    'id': 51,
    'category': 'Material',
    'item_type': 'item type1',
    'item_name': 'item name1',
    'other_details': 'Other details1',
  
  }, {
    'id': 52,
    'category': 'Material',
    'item_type': 'item type2',
    'item_name': 'item name2',
    'other_details': 'Other details2',
   
  }, {
    'id': 53,
    'category': 'Material',
    'item_type': 'item type3',
    'item_name': 'item name03',
    'other_details': 'Other details3',
    
  }, {
    'id': 54,
    'category': 'Dalton',
    'item_type': 'item type4',
    'item_name': 'item name4',
    'other_details': 'Other details4',
    
  }, {
    'id': 55,
    'category': 'Material',
    'item_type': 'item type5',
    'item_name': 'item name5',
    'other_details': 'Other details5',
    
  }, {
    'id': 56,
    'category': 'Material',
    'item_type': 'item type6',
    'item_name': 'item name6',
    'other_details': 'Other details6',
    
  }, {
    'id': 57,
    'category': 'Material',
    'item_type': 'item type7',
    'item_name': 'item name7',
    'other_details': 'Other details7',
    
  }, {
    'id': 58,
    'category': 'Material',
    'item_type': 'item type8',
    'item_name': 'item name8',
    'other_details': 'Other details8',
    
  }, {
    'id': 59,
    'category': 'Material',
    'item_type': 'item type9',
    'item_name': 'item name9',
    'other_details': 'Other details9',
    
  }, {
    'id': 60,
    'category': 'Material',
    'item_type': 'item type10',
    'item_name': 'item name10',
    'other_details': 'Other details10',
   
  }];

  getData() {
    return this.data;
  }
}
